import React, { useEffect, useState, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import Cart_Delete from "./delete-confirmation";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import SWW from "./something-went-wrong";
import TransactSuccess from "./transact-success";
import { notify } from "../pages/toastCreater";
import {
  folioViaAmc,
  getNSEProductCode,
  saveNseTransaction,
  sipTransact,
  getMandateList,
  getSchemeList,
  getElssScheme,
} from "../apisMethods";
import { globalInfo } from "../App";
import { addOnlyYears, addSevenDaysFromCurrent, addThreeMonthsFromCurrent, addYears } from "../currentMonth/FinancialYear";

import ConfirmationProfileChangePopup from "./confirmationProfileChangePopup";
import ConfirmationMandatePopup from "./mandateConfirmationPopup";

const SIPTransact = ({ show, setShow, amcList, userProfileData, isLookingForAnother = false, amountForSip, yearForSip }) => {
  const navigate = useNavigate()
  const { setLoader } = useContext(globalInfo)
  const [confirmShow, setConfirmShow] = useState(false);
  const [errorConfirmationShow, setErrorConfirmationShow] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [amc, setAmc] = useState("");
  const [tempAmc, setTempAmc] = useState({});
  const [folio, setFolio] = useState("");
  const [folioList, setFolioList] = useState([]);
  const [profileName, setProfileName] = useState("");
  const [tempProfileName, setTempProfileName] = useState({});
  const [scheme, setScheme] = useState("");
  const [tempScheme, setTempScheme] = useState({});
  const [amount, setAmount] = useState();
  const [tempAmount, setTempAmount] = useState();
  const [mandateListData, setMandateListData] = useState([]);
  const [mandateUrn, setMandateUrn] = useState("");
  const [tempMandateData, setTempMandateData] = useState({});
  const [headerState, setHeaderState] = useState({});
  const [tempFolio, setTempFolio] = useState({});
  const [nseProductCode, setNseProductCode] = useState("");
  const [allUnitsData, setAllUnitsData] = useState({});
  const [confirmationData, setConfirmationData] = useState({});
  const [confirmationDataArr, setConfirmationDataArr] = useState([]);
  const [sipFrom, setSipFrom] = useState("");
  const [sipTo, setSipTo] = useState();
  const [perpetual, setPerpetual] = useState("Y");
  const [todayDate, setTodayDate] = useState("");
  const [addSipDeatlais, setAddSipDeatlais] = useState([]);
  const [option, setOption] = useState(1);
  const [schemeList, setSchemeList] = useState([]);
  const [sipAllDetails, setSipAllDetails] = useState({});
  const [assetClass, setAssetClass] = useState("1");
  const [amcLoader, setAmcLoader] = useState(false);
  const [folioLoading, setFolioLoading] = useState(false);
  const [schemeLoader, setSchemeLoader] = useState(false);
  const [addThreeMonthFromCurrent, setAddThreeMonthFromCurrent] = useState("");
  const [isProfileChange, setIsProfileChange] = useState(false);
  const [massageContent, setMassageContent] = useState("");
  const [profileTargetValue, setProfileTargetValue] = useState("")
  const [isMandateAvailable, setIsMandateAvailable] = useState(false)

  const refreshForProfile = () => {
    // setAmcListData([]);
    setAmc("");
    setTempAmc({});
    setFolioList([]);
    setFolio("");
    setTempFolio({});
    setScheme("");
    setTempScheme({});
    setSchemeList([]);
    setSipFrom("");
    if (!(amountForSip > 0)) {
      setSipTo("");
      setAmount("");
    }

    setMandateUrn("");
    setTempMandateData({});
    setMandateListData([]);
    setAllUnitsData({});
    setSipAllDetails({});
    setAddSipDeatlais([]);
  };

  const refreshForAmc = () => {
    setFolioList([]);
    setFolio("");
    setTempFolio({});
    setScheme("");
    setTempScheme({});
    setSchemeList([]);
    setMandateUrn("");
    setTempMandateData({});
    setSipFrom("");
    // setSipTo("");
    // setAmount("");
    setSipAllDetails({});
    setAllUnitsData({});
  };

  const refreshForFolio = () => {
    setSipFrom("");
    // setSipTo("");
    // setAmount("");
    setMandateUrn("");
    setTempMandateData({});
    setSipAllDetails({});
    setAllUnitsData({});
    setScheme("");
  };

  const refreshForScheme = () => {
    setSipFrom("");
    // setSipTo("");
    // setAmount("");
    setMandateUrn("");
    setTempMandateData({});
    setSipAllDetails({});
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const headers = { headers: { Authorization: `Bearer ${token}` } };
    setHeaderState(headers);
    let day = addSevenDaysFromCurrent()
    setTodayDate(day)
    let threeMonthAdded = addThreeMonthsFromCurrent()
    if (yearForSip > 0) {
      setSipTo(addOnlyYears(threeMonthAdded, yearForSip))
      setPerpetual("N")
      setAmount(amountForSip)
      setTempAmount(amountForSip);

    } else {
    }
    setAddThreeMonthFromCurrent(threeMonthAdded)

  }, [amountForSip, yearForSip]);

  const amcChangeHandler = (e) => {
    setSchemeLoader(true);
    const value = e.target.value;
    setAmc(value);
    let data = JSON.parse(value);
    setTempAmc(data);
    refreshForAmc();
    if (value && tempProfileName?.name) {
      setFolioLoading(true);
      let joint1stPan = "";
      let joint2ndPan = "";

      if (tempProfileName?.JOINT1_PAN) {
        joint1stPan = tempProfileName?.JOINT1_PAN;
      } else if (tempProfileName?.JOINT1_VALIDPAN?.length > 1) {
        joint1stPan = tempProfileName?.JOINT1_VALIDPAN;
      } else {
        joint1stPan = "";
      }
      if (tempProfileName?.JOINT2_PAN) {
        joint2ndPan = tempProfileName?.JOINT2_PAN;
      } else if (tempProfileName?.JOINT2_VALIDPAN?.length == 10) {
        joint2ndPan = tempProfileName?.JOINT2_VALIDPAN;
      } else {
        joint2ndPan = "";
      }
      const reqBody = {
        investor_pan: tempProfileName?.pan,
        jh1_pan: joint1stPan,
        jh2_pan: joint2ndPan,
        amccode: data.rtaAmcCode,
        gpan: tempProfileName?.gpan ? tempProfileName?.gpan : "",
      };
      folioViaAmc(reqBody, headerState).then((res) => {
        if (!res.data) {
          setFolioLoading(false);
          setFolioList([]);
        } else {
          setFolioList(res.data?.data);
          setFolioLoading(false);
        }
      });
      const reqBodyForScheme = {
        amc_code: Number(data?.amcCode),
        asset_code: Number(assetClass),
        opt_code: Number(option),
        rta: data.rta,
        rtamccode: data?.rtaAmcCode,
      };
      if (isLookingForAnother) {
        const reqBody = {
          amc_code: Number(data?.amcCode),
        }
        getElssScheme(reqBody, headerState).then(res => {
          if (!res.data) {
            setSchemeList([]);
            setSchemeLoader(false)
          } else {
            setSchemeList(res.data?.data);
            setSchemeLoader(false)
          }
        })

      } else {
        if (assetClass && data && option) {
          getSchemeList(reqBodyForScheme, headerState).then((res) => {
            if (!res.data) {
              setSchemeList([]);
              // notify("error","Something went wrong...!")
              setSchemeLoader(false);
            } else {
              setSchemeList(res.data?.data);
              setSchemeLoader(false);
            }
          });
        }
      }

    } else {
      setSchemeLoader(false);
      setFolioLoading(false);
      setFolioList([]);
    }
  };

  const isWantProfileChange = (e) => {
    if (addSipDeatlais?.length > 0) {
      setMassageContent("Change of profile will lead to deletion of added schemes. Are you sure to proceed?")
      setProfileTargetValue(e.target.value)
      setIsProfileChange(true)
    } else {
      profileChangeHandler(e.target.value)
    }

  }
  const profileChangeHandler = (e) => {
    if (e !== "") {
      refreshForProfile();
      let reqData = JSON.parse(e);
      setProfileName(e);
      setTempProfileName(reqData);
      getMandateListCall(reqData.IIN);
    }
  };
  const optionHandler = (e) => {
    setOption(e.target.value)
    setSchemeList([]);
    if (!amc) {
      setSchemeList([]);
      return;
    }
    setSchemeLoader(true);
    let reqBody = {
      amc_code: Number(tempAmc?.amcCode),
      asset_code: Number(assetClass),
      opt_code: Number(e.target?.value),
      rta: tempAmc?.rta,
      rtamccode: tempAmc?.rtaAmcCode,
    };
    getSchemeList(reqBody, headerState).then((res) => {
      if (!res.data) {
        setSchemeList([]);
      } else {
        setSchemeList(res.data?.data);
      }
      setSchemeLoader(false);
    });
  };
  const getMandateListCall = (iin) => {
    getMandateList({ iin }, headerState).then((res) => {
      if (!res.data) {
        setMandateListData([]);
      } else {
        setMandateListData(res.data?.data);
      }
    });
  };

  const changeFolioHandler = (e) => {
    if (e.target?.value) {
      refreshForFolio();
      let jsonData = JSON.parse(e.target?.value);
      setFolio(e.target?.value);
      setTempFolio(jsonData);
    }
  };
  const assetClassHandler = (e) => {
    setAssetClass(e.target.value);
    setSchemeList([]);
    if (!amc) {
      setSchemeList([]);
      return;
    }
    setSchemeLoader(true);
    let reqBody = {
      amc_code: Number(tempAmc?.amcCode),
      asset_code: Number(e.target?.value),
      opt_code: Number(option),
      rta: tempAmc?.rta,
      rtamccode: tempAmc?.rtaAmcCode,
    };
    getSchemeList(reqBody, headerState).then((res) => {
      if (!res.data) {
        setSchemeList([]);
      } else {
        setSchemeList(res.data?.data);
      }
      setSchemeLoader(false);
    });
  };

  const changeSchemeHandler = (e) => {
    let jsonData = {};
    refreshForScheme();
    if (e.target?.value) {
      jsonData = JSON.parse(e.target?.value);
    } else {
      return;
    }

    let reqBody = {
      schemecode: Number(jsonData?.schemecode),
    };
    setScheme(e.target.value);
    setTempScheme(jsonData);
    getNSEProductCode(reqBody, headerState).then((res) => {
      if (!res.data) {
        setNseProductCode("");
        setSipAllDetails({});
        setScheme("");
        setTempScheme({});
        notify("warn", "This scheme is not available");
      } else {
        setNseProductCode(res.data?.data?.productcode);
        setSipAllDetails(res.data?.data);
        if (!res.data?.data?.sip_dates) {
          setScheme("");
          setNseProductCode("");
          setTempScheme({});
          notify("warn", "This scheme not allowed for SIP");
        }
      }
    });
  };

  const checkDate = (selectedDate) => {
    const dataArr = selectedDate.split("-");
    let selectedYear = Number(dataArr[0]);
    let selectedMonth = Number(dataArr[1]);
    let selectedDay = Number(dataArr[2]);
    let date = new Date();
    let currentYear = date.getFullYear();
    let currentMonth = date.getMonth() + 1;
    let currentDay = date.getDate();
    let obj = {
      selectedYear,
      selectedMonth,
      currentYear,
      currentMonth,
      selectedDay,
      currentDay,
    };
    return obj;
  };
  const changeSipFromHandler = (e) => {
    if (!(yearForSip > 0)) {
      setSipTo("")
    }
    const obj = checkDate(e.target?.value);
    if (sipAllDetails?.sip_dates?.includes(obj?.selectedDay) === false) {
      notify("error", "Selected day is not available");
      setSipFrom("");
      return;
    }
    setSipFrom(e.target.value);
  };

  const changeSipToHandler = (e) => {
    if (sipFrom === e.target.value) {
      notify("error", "From date and to date should not be same. ");
      setSipTo("");
      return;
    }
    if (sipFrom > e.target.value) {
      notify("error", "SIP To date should not be less than SIP From. ");
      setSipTo("");
      return;
    }
    setSipTo(e.target.value);
  };
  const amountHandler = (e) => {
    const inputValue = e.target.value;
    if (inputValue < 10000000) {
      const sanitizedValue = inputValue?.replace(/^0+/, ""); // Remove non-numeric characters
      setTempAmount(sanitizedValue)
      setAmount(sanitizedValue);
    } else if (!isNaN(inputValue)) {
      setAmount(10000000);
    }
  };

  const deleteData = (i) => {
    addSipDeatlais.splice(i, 1);
    setAddSipDeatlais([...addSipDeatlais]);
  };

  function formatDate(d) {
    var date = new Date(d);

    if (isNaN(date.getTime())) {
      return d;
    } else {
      var month = new Array();
      month[0] = "JAN";
      month[1] = "FEB";
      month[2] = "MAR";
      month[3] = "APR";
      month[4] = "MAY";
      month[5] = "JUN";
      month[6] = "JUL";
      month[7] = "AUG";
      month[8] = "SEP";
      month[9] = "OCT";
      month[10] = "NOV";
      month[11] = "DEC";

      let day = date.getDate();

      if (day < 10) {
        day = "0" + day;
      }

      return day + "-" + month[date.getMonth()] + "-" + date.getFullYear();
    }
  }

  const addSip = () => {
    if (!amount || !amc || !profileName || !scheme || !sipFrom || !mandateUrn) {
      notify("error", "All Fields Required.");
      return;
    }
    if (Number(amount) < 1000) {
      notify("error", "Amount should be greater than min amount");
      return;
    }
    if (perpetual === "N" && !sipTo) {
      notify("warn", "Please select SIP To date");
      return;
    }


    if (Number(tempMandateData.AMOUNT) < Number(tempAmount)) {
      notify("warn", "Entered amount can't be greater than Mandate Amount");
      return;
    }
    document.getElementById('scroll').scrollIntoView(true);
    const fromDateParts = sipFrom?.split("-");
    const toDateParts = sipTo?.split("-");
    const fromDate = new Date(
      parseInt(fromDateParts[0]),
      parseInt(fromDateParts[1]) - 1,
      parseInt(fromDateParts[2])
    );
    const toDate = new Date(
      parseInt(toDateParts[0]),
      parseInt(toDateParts[1]) - 1,
      parseInt(toDateParts[2])
    );

    setAddSipDeatlais([
      ...addSipDeatlais,
      {
        amc: tempAmc?.rtaAmcCode,
        profileName: tempProfileName?.name,
        folio: tempFolio?.folio ? tempFolio?.folio : "",
        product_code: sipAllDetails?.productcode,
        reinvest: sipAllDetails?.REINVEST_TAG,
        sip_from_date: formatDate(fromDate),
        sip_end_date: perpetual == "N" ? formatDate(toDate) : "",
        sip_freq: "OM",
        sip_amount: amount,
        sip_period_day: fromDateParts[2],
        input_ref_no: "",
        perpetual_flag: perpetual,
        schemeName: tempScheme?.schemeName,
        amt_unit_type: "Amount"
      },
    ]);
    setAmc("");
    setTempAmc({});
    setFolioList([]);
    setFolio("");
    setTempFolio({});
    setScheme("");
    setTempScheme({});
    setSchemeList([]);
    setSipFrom("");
    setSipTo("");
    setAmount("");
    setMandateUrn("");
    // setTempMandateData({})
    setAllUnitsData({});
    setSipAllDetails({});
  };

  const mandateChangeHandler = (e) => {
    if (e.target.value) {
      let mandateData = JSON.parse(e.target.value);
      console.log("mandateData",mandateData);
      
      setMandateUrn(e.target.value);
      setTempMandateData(mandateData);
    }
  };
  const OrderSip = () => {
    if (addSipDeatlais.length === 0) {
      notify("warn", "Plesae add first");
      return
    }
    setLoader('block')
    const totalAmount = addSipDeatlais?.reduce((accumulator, currentObject) => {
      return accumulator + Number(currentObject?.sip_amount);
    }, 0);
    const fromDateParts = sipFrom?.split("-");
    const toDateParts = sipTo?.split("-");
    const fromDate = new Date(
      parseInt(fromDateParts[0]),
      parseInt(fromDateParts[1]) - 1,
      parseInt(fromDateParts[2])
    );
    const toDate = new Date(
      parseInt(toDateParts[0]),
      parseInt(toDateParts[1]) - 1,
      parseInt(toDateParts[2])
    );
    let reqBody = {};
    let i = 0;
    let loaclArr = [];
    let localObj = {}
    let paymentUrl = "";

    reqBody = {
      email: tempProfileName?.email,
      holder_name: tempProfileName?.name,
      tax_status: tempProfileName?.taxStatus,
      umrn: tempMandateData.UMRN_NO,
      iin: tempProfileName?.IIN,
      instrm_amount: totalAmount,
      ach_amt: totalAmount,
      instrm_date: tempMandateData?.MANDATE_DATE, //============
      accountNo: tempMandateData?.ACCOUNT_NO,
      debit_amount_type: "Amount",
      bank_code: tempMandateData?.BANK_CODE,
      sub_trxn_type: "S",
      Return_paymnt_flag: "N",
      Client_callback_url: "",
      ach_exist: "Y",
      trxn_acceptance: "OL",
      payment_mode: "M",
      schemes: addSipDeatlais,
    };

    sipTransact(reqBody, headerState).then((res) => {
      if (res.data?.status === 200) {
        if (addSipDeatlais.length === 1) {
          let nseData = {
            Unique_No: res.data?.data?.Unique_No,
            Trxn_No: res.data?.data?.Trxn_No,
            Application_No: res.data?.data?.Application_No,
            Fund: res.data?.data?.Fund,
            Scheme: res.data?.data?.Scheme,
            Scheme_Name: res.data?.data?.Scheme_Name,
            Amt: res.data?.data?.Amt,
            Status_Desc: res.data?.data?.Status_Desc,
            Status_code: res?.data?.data?.Status_code,
            Folio: res.data?.data?.Folio,
            Platform: "Web",
            transaction_type: "SIP",
            iin: tempProfileName?.IIN,
            Pan: tempProfileName?.pan,
            Gpan: tempProfileName?.gpan,
            Joint1pan: tempProfileName?.JOINT1_PAN,
            Joint2pan: tempProfileName?.JOINT2_PAN,
            name: tempProfileName?.name,
          };
          localObj = res.data?.data
          saveNseTransaction(nseData, headerState).then((res) => {
            setConfirmShow(true);
            setConfirmationData(localObj);
            setShow(false);
            setConfirmationDataArr([]);
            refreshForProfile();
            setTempProfileName({});
            setProfileName("");
          });
          if (localObj?.Paymentlink) {
            window.open(res.data?.data?.Paymentlink);
            paymentUrl = res.data?.data?.Paymentlink;
          }
        } else {
          loaclArr = res.data?.data;
          for (let i = 0; i < addSipDeatlais.length; i++) {
            let nseData = {
              Unique_No: res.data?.data[i].Unique_No,
              Trxn_No: res.data?.data[i].Trxn_No,
              Application_No: res.data?.data[i].Application_No,
              Fund: res.data?.data[i].Fund,
              Scheme: res.data?.data[i].Scheme,
              Scheme_Name: res.data?.data[i].Scheme_Name,
              Amt: res.data?.data[i].Amt,
              Status_Desc: res.data?.data[i].Status_Desc,
              Status_code: res?.data?.data[i].Status_code,
              Folio: res.data?.data[i].Folio,
              Platform: "Web",
              transaction_type: "SIP",
              iin: tempProfileName?.IIN,
              Pan: tempProfileName?.pan,
              Gpan: tempProfileName?.gpan,
              Joint1pan: tempProfileName?.JOINT1_PAN,
              Joint2pan: tempProfileName?.JOINT2_PAN,
              name: tempProfileName?.name,
            };


            saveNseTransaction(nseData, headerState).then((res) => {
              setConfirmShow(true);
              setConfirmationData({});
              setShow(false);
              setConfirmationDataArr(loaclArr);
              refreshForProfile();
              setTempProfileName({});
              setProfileName("");
            });
          }
          if (res.data?.data?.Paymentlink) {
            window.open(res.data?.data?.Paymentlink);
            paymentUrl = res.data?.data?.Paymentlink;
          }
        }

      } else {
        setConfirmationDataArr([]);
        setConfirmationData({});
        setErrorMsg(res.data?.msg);
        setErrorConfirmationShow(true);
        setAddSipDeatlais([]);
      }
      setLoader('none')
    });

  };

  const checkMandate = () => {
    if (profileName && mandateListData.length === 0) {
      setIsMandateAvailable(true)
    }
  }
  const dateAny = (id) => {
    const inputDateElement = document.getElementById(id);
    inputDateElement.showPicker();
  };
  return (
    <>
      <div className="">
        <Modal
          show={show}
          size="lg"
          onHide={() => setShow(false)}
          // onClick={refreshForProfile}
          className="invest-gray"
          scrollable={true}
          centered
        >
          <Modal.Header className="" closeButton>
            <Modal.Title className="fs-5">SIP</Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-4" style={{ scrollBehavior: "smooth" }}>
            <Form>
              <div className="row mb-2">
                <div className="col-md-4">
                  <Form.Group>
                    <Form.Label>
                      Select Profile <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      className="bg-c"
                      value={profileName}
                      onChange={isWantProfileChange}
                    >
                      <option value="">Select Profile</option>
                      {userProfileData?.length > 0
                        ? userProfileData.map((el) => (
                          <option
                            value={JSON.stringify({
                              email: el.EMAIL,
                              taxStatus: el.TAX_STATUS,
                              IIN: el.IIN,
                              name: el.NAME,
                              pan: el.PAN,
                              gpan: el?.GUARD_PAN,
                              jtname1: el?.JOINT_NAME1,
                              jtname2: el?.JOINT_NAME2,
                              nominee: el?.NOM_NAME,
                              JOINT1_PAN: el.JOINT1_PAN,
                              JOINT2_PAN: el.JOINT2_PAN,
                              JOINT1_VALIDPAN: el.JOINT1_VALIDPAN,
                              JOINT2_VALIDPAN: el.JOINT2_VALIDPAN,
                              bankName: el.BANK_NAME,
                              bankCode: el.BANK_CODE,
                              accountNo: el.AC_NO,
                              accountType: el.AC_TYPE,
                              ifscCode: el.IFSC_CODE,
                              branchName: el.BRANCH_NAME,
                              branchAddress: el.BRANCH_ADDRESS1,
                            })}
                            key={el.PAN}
                          >
                            {`${el.NAME} ${el.JOINT_NAME1 && '|'} ${el.JOINT_NAME1}`}
                          </option>
                        ))
                        : null}
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="col-lg-4 col-md-6">
                  <Form.Group>
                    <Form.Label>
                      Select AMC <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      className="bg-c"
                      placeholder="Select AMC"
                      onChange={amcChangeHandler}
                      value={amc}
                      disabled={amcLoader}
                    >
                      <option value="">Select AMC</option>
                      {amcList.map((el) => (
                        <option
                          value={JSON.stringify({
                            amcName: el.s_name,
                            amcCode: el.amc_code,
                            rtaAmcCode: el.rtamccode,
                            rta: el.rta,
                          })}
                          key={el.s_name}
                        >
                          {el.s_name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="col-lg-4 col-md-6">
                  <Form.Group>
                    <Form.Label>
                      Select Folio <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      className="bg-c"
                      value={folio}
                      onChange={changeFolioHandler}
                      disabled={folioLoading}
                    >
                      {/* <option value="">Select folio</option> */}
                      <option value="">New folio</option>
                      {folioList &&
                        folioList.map((el) => (
                          <option
                            value={JSON.stringify({
                              folio: el.folio,
                              accord_schemecode: el.accord_schemecode,
                              rta: el.rta,
                            })}
                            key={el.PAN}
                          >
                            {el.folio}
                          </option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                </div>
              </div>
              <div className="row mb-2 py-3">
                {/* <div className="col-md-6">
                  <Form.Group
                    className="pt-lg-4  d-flex"
                    controlId="formBasicRadio"
                  >
                    <Form.Check
                      type="radio"
                      label="Existing Scheme"
                      name="formHorizontalRadios"
                      value={"true"}
                      checked={schemeType == "true"}
                      onChange={schemeDataCall}
                      id="existingScheme"
                      className="pe-3 fs-sm-14"
                    />
                    <Form.Check
                      type="radio"
                      label="New Scheme"
                      name="formHorizontalRadios"
                      value={"false"}
                      checked={schemeType == "false"}
                      onChange={schemeDataCall}
                      id="newScheme"
                      className="pe-3 fs-sm-14"
                    />
                  </Form.Group>
                </div> */}
                <div className="row mb-2 pe-0">
                  {isLookingForAnother ? "" : <> <div className="col-md-4">
                    <Form.Group className=" " controlId="formBasicRadio">
                      <Form.Label>
                        Asset Class <span className="text-danger">*</span>
                      </Form.Label>
                      <div className="d-flex">
                        <Form.Check
                          type="radio"
                          label="Equity"
                          value={"1"}
                          checked={assetClass == "1"}
                          onChange={assetClassHandler}
                          name="formHorizontalRadios1"
                          id="equity"
                          className="pe-3"
                        />
                        <Form.Check
                          type="radio"
                          label="Debt"
                          value={"3"}
                          checked={assetClass == "3"}
                          onChange={assetClassHandler}
                          name="formHorizontalRadios1"
                          id="debt"
                          className="pe-3"
                        />
                        <Form.Check
                          type="radio"
                          label="Other"
                          value={"2"}
                          checked={assetClass == "2"}
                          onChange={assetClassHandler}
                          name="formHorizontalRadios1"
                          id="other"
                          className="pe-3"
                        />
                      </div>
                    </Form.Group>
                  </div>
                    <div className="col-md-3">
                      <Form.Group className=" " controlId="formBasicRadio">
                        <Form.Label>
                          Option <span className="text-danger">*</span>
                        </Form.Label>
                        <div className="d-flex">
                          <Form.Check
                            type="radio"
                            label="Growth"
                            name="optionSip"
                            id="Growth"
                            value={1}
                            checked={option == 1}
                            onChange={optionHandler}
                            className="pe-3"
                          />
                          <Form.Check
                            type="radio"
                            label="IDCW"
                            name="optionSip"
                            value={2}
                            checked={option == 2}
                            onChange={optionHandler}
                            id="IDCW"
                            className="pe-3"
                          />
                        </div>
                      </Form.Group>
                    </div></>}


                  <div className="col-md-5 pe-0">
                    <Form.Group>
                      <Form.Label>
                        Select Scheme<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Select
                        className="bg-c"
                        value={scheme}
                        onChange={changeSchemeHandler}
                        disabled={schemeLoader}
                      >
                        <option value={""}>Select Scheme</option>
                        {schemeList.length > 0
                          ? schemeList.map((el) => {
                            return (
                              <option
                                value={JSON.stringify({
                                  s_name: el.s_name,
                                  schemecode: el.schemecode,
                                  minimumAmount: el.mininvt,
                                  productCode: el.productcode,
                                  reinvest: el.reinvest,
                                  schemeName: el.s_name,
                                })}
                                key={el.s_name}
                              >
                                {el.s_name}
                              </option>
                            );
                          })
                          : null}
                      </Form.Select>
                    </Form.Group>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-6">
                  <Form.Group>
                    <Form.Label>
                      Enter Amount <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      className="bg-c"
                      type="text"
                      placeholder="Enter Amount"
                      value={amount}
                      onChange={amountHandler}
                    />
                    <small className="text-label float-right">
                      Min Amount : 1000
                    </small>
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group>
                    <Form.Label>
                      Select Mandate <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      className="bg-c"
                      value={mandateUrn}
                      onChange={mandateChangeHandler}
                      onClick={checkMandate}
                    >
                      <option value={""}>select</option>
                      {mandateListData.length > 0 &&
                        mandateListData.map((data) => (
                          <option
                            value={JSON.stringify({
                              UMRN_NO: data.UMRN_NO,
                              BANK_CODE: data.BANK_CODE,
                              ACCOUNT_NO: data.ACCOUNT_NO,
                              MANDATE_DATE: data.MANDATE_DATE,
                              AC_TYPE: data.AC_TYPE,
                              AMOUNT: data.AMOUNT,
                            })}
                          >{`${data?.BANK_NAME} | Ac No-  ${data?.ACCOUNT_NO
                            } |  Amount- ${data?.AMOUNT > 0 ? data?.AMOUNT : "0"
                            }`}</option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                  {/* <Link
                    to="/dashboard/bank-and-mandate"
                    className="text-red fs-13"
                  >
                    Create Mandate
                  </Link> */}
                </div>

              </div>
              <div className="row">
                <div className=" col-md-5">
                  <Form.Group>
                    <Form.Label>
                      SIP From <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      className="bg-c"
                      min={todayDate}
                      max={addThreeMonthFromCurrent}
                      type="date"
                      value={sipFrom}
                      onChange={changeSipFromHandler}
                      id="anyWhere5"
                      onClick={() => dateAny("anyWhere5")}
                    />
                    <small className="text-danger">
                      {sipAllDetails?.sip_dates ? sipAllDetails?.sip_dates : ""}
                    </small>
                  </Form.Group>
                </div>
                <div className="col-md-3 align-self-center">
                  <Form.Group className="d-flex mt-2">
                    <Form.Check
                      label="Perpetual"
                      name="formHorizontalRadios1"
                      id="perpetual"
                      checked={perpetual === "Y"}
                      className="pe-3"
                      value={perpetual}
                      onChange={(e) =>
                        setPerpetual(e.target.value === "N" ? "Y" : "N")
                      }
                    />

                  </Form.Group>
                </div>
                {perpetual === "N" ? (
                  <div className=" col-md-4">
                    <Form.Group>
                      <Form.Label>
                        SIP To<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        className="bg-c"
                        min={addThreeMonthFromCurrent}
                        id="anyWhere6"
                        onClick={() => dateAny("anyWhere6")}
                        type="date"
                        value={sipTo}
                        onChange={changeSipToHandler}
                      />
                    </Form.Group>
                  </div>
                ) : null}
              </div>
              {/* <div className="row align-items-stretch">
              

                <div className="col-md-3 align-self-center">
                  <Form.Group className="d-flex mt-4">
                    <Form.Check
                      label="Perpetual"
                      name="formHorizontalRadios1"
                      id="perpetual"
                      // checked
                      className="pe-3"
                      value={perpetual}
                      onChange={(e) =>
                        setPerpetual(e.target.value === "N" ? "Y" : "N")

                      }
                    />
                    <spna className="text-danger">*</spna>
                  </Form.Group>
                </div>
              </div> */}
              <div className="col-md-12 " onClick={addSip}>
                <a
                  href="javascript:void(0)"
                  className="btn btn-danger float-right my-4"
                >
                  + Add
                </a>
              </div>
              <div className="col-md-12 px-0" id="tbt">
                <div className="table-responsive w-100" >
                  <table className="table custom bg-white mt-5 mb-3" >
                    <tr>
                      <th>Profile</th>
                      <th className="wd-7rem">Scheme Name</th>
                      <th className="wd-7rem">Folio Number</th>
                      <th className="wd-7rem">SIP From</th>
                      <th className="wd-7rem">SIP To</th>
                      <th>Amount</th>
                      <th></th>
                    </tr>
                    {addSipDeatlais.length > 0
                      ? addSipDeatlais.map((data, i) => {
                        return (
                          <tr>
                            <td>{data.profileName}</td>
                            <td>{data.schemeName}</td>
                            <td>{data.folio ? data.folio : "New Folio"}</td>
                            <td>{data.sip_from_date}</td>
                            <td>
                              {data.sip_end_date
                                ? data.sip_end_date
                                : "Perpetual"}
                            </td>
                            <td>{data.sip_amount}</td>
                            <td>
                              <Cart_Delete id={i} deleteData={deleteData} />
                            </td>
                          </tr>
                        );
                      })
                      : null}
                  </table>
                  <div id="scroll"></div>
                </div>

              </div>
              <div className="border-0 trans" >
                <button
                  className="btn-custom"
                  type="button"
                  onClick={OrderSip}
                >
                  Order Now
                </button>
              </div>

            </Form>
          </Modal.Body>
          {/* <Modal.Footer className="border-0" onClick={OrderSip}>
            <button
              className="btn-custom"
              type="button"
            // onClick={closeInnerModal}
            >
              Order Now
            </button>
          </Modal.Footer> */}
        </Modal>
      </div>
      <SWW
        show={errorConfirmationShow}
        setShows={setErrorConfirmationShow}
        errorMsg={errorMsg}
      />
      <TransactSuccess
        show={confirmShow}
        setShow={setConfirmShow}
        confirmData={confirmationData}
        confirmationDataArr={confirmationDataArr}
      />
      <ConfirmationProfileChangePopup
        show={isProfileChange}
        setShow={setIsProfileChange}
        massageContent={massageContent}
        profileChangeHandler={profileChangeHandler}
        profileTargetValue={profileTargetValue}
      />
      <ConfirmationMandatePopup
        show={isMandateAvailable}
        setShow={setIsMandateAvailable}
        profileDetail={{ name: tempProfileName?.name, iin: tempProfileName?.IIN, pan: tempProfileName?.pan, gpan: tempProfileName?.gpan }}
      />
    </>
  );
};
export default SIPTransact;
